import React from "react"
import { graphql } from "gatsby"
import { H1, PX3, Max2xl, Max5xl } from "../components/blocks"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PostIndex } from "../components/post"
import moment from "moment"

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const aktuelles = data.aktuelles.edges.map(x => {
    x.kind = "aktuelles"
    return x
  })

  const appointments = data.appointments.edges
    .map(x => {
      x.kind = "appointment"
      return x
    })
    .filter(post =>
      post.node.frontmatter.end
        ? moment().isSameOrBefore(post.node.frontmatter.end, "day")
        : moment().isSameOrBefore(post.node.frontmatter.start, "day")
    )

  const posts = [...appointments, ...aktuelles].filter(post =>
    post.node.frontmatter.archiveAt
      ? moment().isBefore(post.node.frontmatter.archiveAt, "day")
      : true
  )

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Aktuelles" />
      <Max5xl className="h-full pb-2">
        <Max2xl className="h-full">
          <PX3>
            <H1>Aktuelles</H1>
            <PostIndex posts={posts} />
          </PX3>
        </Max2xl>
      </Max5xl>
    </Layout>
  )
}

export const aktuellesQuery = graphql`
  query aktuellesSites {
    site {
      siteMetadata {
        title
      }
    }

    ...CurrentAppointments

    aktuelles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/aktuelles/**/*.md" }
        frontmatter: { state: { eq: "current" } }
      }

      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      ...PostIndexInformation
    }
  }
`
